<template>
  <div class="nav-title">
    <div class="flex items-center mr16">
      <img v-if="showBack" class="icon-back" src="@/assets/images/arrow-left.png" @click="goBack()">
      <span class="title" v-if="title">{{title}}</span>
    </div>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showBack: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    goBack() {
      this.$emit('back')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: #fff;
}
.icon-back {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 10px;
}
.title {
  font-size: 20px;
  font-weight: bold;
}
</style>